export const wordsList = [
  {
    mainWord: "Qladiator",
    relatedWords: ["döyüşçü", "Roma", "arena", "qılınc", "döyüş"],
    englishWord: "Gladiator",
    turkishWord: "Gladyatör",
  },
  {
    mainWord: "Titanik",
    relatedWords: ["gəmi", "okean", "buz", "qəza", "səyahət"],
    englishWord: "Titanic",
    turkishWord: "Titanik",
  },
  {
    mainWord: "balıq",
    relatedWords: ["su", "üzgəc", "dəniz", "okean", "qəlsəmə"],
    englishWord: "fish",
    turkishWord: "balık",
  },
  {
    mainWord: "Albert Einstein",
    relatedWords: ["fizika", "nisbilik nəzəriyyəsi", "alim", "Nobel", "E=mc²"],
    englishWord: "Albert Einstein",
    turkishWord: "Albert Einstein",
  },
  {
    mainWord: "Isaac Newton",
    relatedWords: ["alma", "cazibə qüvvəsi", "fizika", "riyaziyyat", "alim"],
    englishWord: "Isaac Newton",
    turkishWord: "Isaac Newton",
  },
  // ... existing code ...
  {
    mainWord: "Galileo Galilei",
    relatedWords: ["teleskop", "astronomiya", "fizika", "yer kürəsi", "alim"],
    englishWord: "Galileo Galilei",
    turkishWord: "Galileo Galilei", // Added Turkish word
  },
  {
    mainWord: "xaş",
    relatedWords: ["yemək", "sup", "ət", "səhər", "restoran"],
    englishWord: "khash",
    turkishWord: "Khash", // Added Turkish word
  },
  {
    mainWord: "diş",
    relatedWords: ["ağız", "fırça", "həkim", "pasta", "ağrı"],
    englishWord: "tooth",
    turkishWord: "diş", // Added Turkish word
  },
  {
    mainWord: "traynik",
    relatedWords: ["təcrübə", "öyrənmək", "yeni", "iş", "başlanğıc"],
    englishWord: "trainee",
    turkishWord: "stajyer", // Added Turkish word
  },
  {
    mainWord: "Marie Curie",
    relatedWords: ["radioaktivlik", "fizika", "kimya", "Nobel", "elm"],
    englishWord: "Marie Curie",
    turkishWord: "Marie Curie", // Added Turkish word
  },
  {
    mainWord: "Charles Darwin",
    relatedWords: ["təkamül", "biologiya", "növlər", "elm", "təbiət"],
    englishWord: "Charles Darwin",
    turkishWord: "Charles Darwin", // Added Turkish word
  },
  {
    mainWord: "Leonardo da Vinci",
    relatedWords: ["rəssam", "ixtiraçı", "Mona Lisa", "sənət", "alim"],
    englishWord: "Leonardo da Vinci",
    turkishWord: "Leonardo da Vinci", // Added Turkish word
  },
  {
    mainWord: "alma",
    relatedWords: ["meyvə", "ağac", "yaşıl", "qırmızı", "şirin"],
    englishWord: "apple",
    turkishWord: "elma", // Added Turkish word
  },
  {
    mainWord: "dovşan",
    relatedWords: ["heyvan", "uzun qulaq", "yerkökü", "sürətli", "yuva"],
    englishWord: "rabbit",
    turkishWord: "tavşan", // Added Turkish word
  },
  {
    mainWord: "pomidor",
    relatedWords: ["qırmızı", "tərəvəz", "salat", "bitki", "şirəli"],
    englishWord: "tomato",
    turkishWord: "domates", // Added Turkish word
  },
  {
    mainWord: "pişik",
    relatedWords: ["miyoldamaq", "ev heyvanı", "süd", "siçan", "yumşaq"],
    englishWord: "cat",
    turkishWord: "kedi", // Added Turkish word
  },
  {
    mainWord: "limon",
    relatedWords: ["turş", "sarı", "sitrus", "çay", "meyvə"],
    englishWord: "lemon",
    turkishWord: "limon", // Added Turkish word
  },
  {
    mainWord: "yırtıcı",
    relatedWords: ["heyvan", "ov", "vəhşi", "təhlükəli", "güclü"],
    englishWord: "predator",
    turkishWord: "yırtıcı", // Added Turkish word
  },
  {
    mainWord: "qoyun",
    relatedWords: ["yun", "heyvan", "çoban", "otlaq", "süd"],
    englishWord: "sheep",
    turkishWord: "koyun", // Added Turkish word
  },
  {
    mainWord: "naringi",
    relatedWords: ["sitrus", "meyvə", "qabıq", "dilim", "şirin"],
    englishWord: "tangerine",
    turkishWord: "mandalina", // Added Turkish word
  },
  {
    mainWord: "çəkmə",
    relatedWords: ["ayaqqabı", "dəri", "geyim", "bağ", "qış"],
    englishWord: "boot",
    turkishWord: "çizme", // Added Turkish word
  },
  {
    mainWord: "quş",
    relatedWords: ["qanad", "uçmaq", "dimdik", "yumurta", "lələk"],
    englishWord: "bird",
    turkishWord: "kuş", // Added Turkish word
  },
  {
    mainWord: "banan",
    relatedWords: ["meyvə", "sarı", "meymun", "tropical", "şirin"],
    englishWord: "banana",
    turkishWord: "muz", // Added Turkish word
  },
  {
    mainWord: "kələm",
    relatedWords: ["tərəvəz", "yaşıl", "yarpaq", "dolma", "bağ"],
    englishWord: "cabbage",
    turkishWord: "lahana", // Added Turkish word
  },
  {
    mainWord: "at",
    relatedWords: ["nal", "yəhər", "qaçış", "heyvan", "çapmaq"],
    englishWord: "horse",
    turkishWord: "at", // Added Turkish word
  },
  {
    mainWord: "albalı",
    relatedWords: ["meyvə", "qırmızı", "şirin", "ağac", "kompot"],
    englishWord: "cherry",
    turkishWord: "kiraz", // Added Turkish word
  },
  {
    mainWord: "kartof",
    relatedWords: ["tərəvəz", "qızartma", "yumru", "torpaq", "yemək"],
    englishWord: "potato",
    turkishWord: "patates", // Added Turkish word
  },
  {
    mainWord: "xiyar",
    relatedWords: ["tərəvəz", "yaşıl", "salat", "turşu", "təzə"],
    englishWord: "cucumber",
    turkishWord: "salatalık", // Added Turkish word
  },
  {
    mainWord: "nar",
    relatedWords: ["meyvə", "qırmızı", "dənə", "şirə", "turş"],
    englishWord: "pomegranate",
    turkishWord: "nar", // Added Turkish word
  },
  {
    mainWord: "ördək",
    relatedWords: ["quş", "su", "üzmək", "dimdik", "göl"],
    englishWord: "duck",
    turkishWord: "ördek", // Added Turkish word
  },
  {
    mainWord: "portfel",
    relatedWords: ["çanta", "məktəb", "kitab", "dəftər", "daşımaq"],
    englishWord: "briefcase",
    turkishWord: "evrak çantası", // Added Turkish word
  },
  {
    mainWord: "delfin",
    relatedWords: ["məməli", "okean", "ağıllı", "üzmək", "balıq"],
    englishWord: "dolphin",
    turkishWord: "yunus", // Added Turkish word
  },
  {
    mainWord: "kök",
    relatedWords: ["tərəvəz", "narıncı", "dovşan", "şirin", "vitamin"],
    englishWord: "carrot",
    turkishWord: "havuç", // Added Turkish word
  },
  {
    mainWord: "tülkü",
    relatedWords: ["heyvan", "quyruq", "hiyləgər", "meşə", "qırmızı"],
    englishWord: "fox",
    turkishWord: "tilki", // Added Turkish word
  },
  {
    mainWord: "şapka",
    relatedWords: ["baş", "geyim", "günəş", "qoruma", "moda"],
    englishWord: "hat",
    turkishWord: "şapka", // Added Turkish word
  },
  {
    mainWord: "qarğa",
    relatedWords: ["quş", "qara", "uçmaq", "dimdik", "ağıllı"],
    englishWord: "crow",
    turkishWord: "karga", // Added Turkish word
  },
  {
    mainWord: "kişi",
    relatedWords: ["insan", "saqqal", "ata", "güclü", "kişilik"],
    englishWord: "man",
    turkishWord: "adam", // Added Turkish word
  },
  {
    mainWord: "toyuq",
    relatedWords: ["quş", "yumurta", "ferma", "qanad", "ev quşu"],
    englishWord: "chicken",
    turkishWord: "tavuk", // Added Turkish word
  },
  {
    mainWord: "üzüm",
    relatedWords: ["meyvə", "şərab", "tənək", "salxım", "şirin"],
    englishWord: "grape",
    turkishWord: "üzüm", // Added Turkish word
  },
  {
    mainWord: "qreypfrut",
    relatedWords: ["meyvə", "sitrus", "acı", "sarı", "vitamin"],
    englishWord: "grapefruit",
    turkishWord: "greyfurt", // Added Turkish word
  },
  {
    mainWord: "qab",
    relatedWords: ["mətbəx", "yemək", "boşqab", "çini", "saxlamaq"],
    englishWord: "dish",
    turkishWord: "tabak", // Added Turkish word
  },
  {
    mainWord: "şort",
    relatedWords: ["geyim", "yay", "qısa", "idman", "sərin"],
    englishWord: "shorts",
    turkishWord: "şort", // Added Turkish word
  },
  {
    mainWord: "daş",
    relatedWords: ["bərk", "ağır", "mineral", "qaya", "torpaq"],
    englishWord: "stone",
    turkishWord: "taş", // Added Turkish word
  },
  {
    mainWord: "ananas",
    relatedWords: ["meyvə", "tropical", "şirin", "sarı", "tikanlı"],
    englishWord: "pineapple",
    turkishWord: "ananas", // Added Turkish word
  },
  {
    mainWord: "şam",
    relatedWords: ["işıq", "od", "yanmaq", "mum", "qaranlıq"],
    englishWord: "candle",
    turkishWord: "mum", // Added Turkish word
  },
  {
    mainWord: "qutu",
    relatedWords: ["saxlamaq", "bağlama", "hədiyyə", "karton", "qapaq"],
    englishWord: "box",
    turkishWord: "kutu", // Added Turkish word
  },
  {
    mainWord: "it",
    relatedWords: ["heyvan", "sadiq", "hürmək", "ev heyvanı", "dost"],
    englishWord: "dog",
    turkishWord: "köpek", // Added Turkish word
  },
  {
    mainWord: "Xoşqədəm",
    relatedWords: ["aparıcı", "televiziya", "veriliş", "məşhur", "jurnalist"],
    englishWord: "Khoshgadam",
    turkishWord: "Khoshgadam", // Added Turkish word
  },
  {
    mainWord: "Elgiz",
    relatedWords: ["aparıcı", "televiziya", "veriliş", "məşhur", "jurnalist"],
    englishWord: "Elgiz",
    turkishWord: "Elgiz", // Added Turkish word
  },
  {
    mainWord: "cücə",
    relatedWords: ["toyuq", "balaca", "sarı", "yumurta", "ferma"],
    englishWord: "chick",
    turkishWord: "civciv", // Added Turkish word
  },
  {
    mainWord: "əlcək",
    relatedWords: ["əl", "qış", "isti", "geyim", "qoruyucu"],
    englishWord: "glove",
    turkishWord: "eldiven", // Added Turkish word
  },
  {
    mainWord: "armud",
    relatedWords: ["meyvə", "şirin", "yaşıl", "ağac", "yumşaq"],
    englishWord: "pear",
    turkishWord: "armut", // Added Turkish word
  },
  {
    mainWord: "çay",
    relatedWords: ["isti", "içki", "dəmləmək", "stəkan", "limon"],
    englishWord: "tea",
    turkishWord: "çay", // Added Turkish word
  },
  {
    mainWord: "gilas",
    relatedWords: ["meyvə", "qırmızı", "şirin", "ağac", "yay"],
    englishWord: "cherry",
    turkishWord: "vişne", // Added Turkish word
  },
  {
    mainWord: "yemiş",
    relatedWords: ["meyvə", "şirin", "sarı", "yay", "toxum"],
    englishWord: "melon",
    turkishWord: "kavun", // Added Turkish word
  },
  {
    mainWord: "kirə",
    relatedWords: ["ev", "pul", "müqavilə", "yaşayış", "ödəniş"],
    englishWord: "rent",
    turkishWord: "kira", // Added Turkish word
  },
  {
    mainWord: "özgüç",
    relatedWords: ["üzmək", "su", "idman", "hovuz", "yarış"],
    englishWord: "swimmer",
    turkishWord: "yüzücü", // Added Turkish word
  },
  {
    mainWord: "şəfəq",
    relatedWords: ["səhər", "günəş", "işıq", "səma", "qızartı"],
    englishWord: "dawn",
    turkishWord: "şafak", // Added Turkish word
  },
  {
    mainWord: "şaftalı",
    relatedWords: ["meyvə", "şirin", "tüklü", "yay", "ağac"],
    englishWord: "peach",
    turkishWord: "şeftali", // Added Turkish word
  },
  {
    mainWord: "öküz",
    relatedWords: ["heyvan", "kənd", "güclü", "buynuz", "otlaq"],
    englishWord: "ox",
    turkishWord: "öküz", // Added Turkish word
  },
  {
    mainWord: "yelləncək",
    relatedWords: ["uşaq", "park", "əyləncə", "oynamaq", "yellənmək"],
    englishWord: "swing",
    turkishWord: "salıncak", // Added Turkish word
  },
  {
    mainWord: "məktəb",
    relatedWords: ["təhsil", "müəllim", "şagird", "dərs", "sinif"],
    englishWord: "school",
    turkishWord: "okul", // Added Turkish word
  },
  {
    mainWord: "zebra",
    relatedWords: ["heyvan", "zolaqlı", "Afrika", "ağ-qara", "vəhşi"],
    englishWord: "zebra",
    turkishWord: "zebra", // Added Turkish word
  },
  {
    mainWord: "çalğı",
    relatedWords: ["musiqi", "alət", "səs", "not", "melodiya"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "ev",
    relatedWords: ["yaşayış", "bina", "otaq", "ailə", "mənzil"],
    englishWord: "house",
    turkishWord: "ev", // Added Turkish word
  },
  {
    mainWord: "kivi",
    relatedWords: ["meyvə", "yaşıl", "tüklü", "turş", "vitamin"],
    englishWord: "kiwi",
    turkishWord: "kivi", // Added Turkish word
  },
  {
    mainWord: "kassir",
    relatedWords: ["pul", "mağaza", "hesab", "ödəniş", "satış"],
    englishWord: "cashier",
    turkishWord: "kasiyer", // Added Turkish word
  },
  {
    mainWord: "çuğundur",
    relatedWords: ["tərəvəz", "qırmızı", "şirin", "bağ", "salat"],
    englishWord: "beetroot",
    turkishWord: "pancar", // Added Turkish word
  },
  {
    mainWord: "dəftər",
    relatedWords: ["kağız", "yazmaq", "məktəb", "vərəq", "qeyd"],
    englishWord: "notebook",
    turkishWord: "defter", // Added Turkish word
  },
  {
    mainWord: "badımcan",
    relatedWords: ["tərəvəz", "bənövşəyi", "yemək", "bağ", "qızartma"],
    englishWord: "eggplant",
    turkishWord: "patlıcan", // Added Turkish word
  },
  {
    mainWord: "yazar",
    relatedWords: ["kitab", "ədəbiyyat", "qələm", "roman", "hekayə"],
    englishWord: "writer",
    turkishWord: "yazar", // Added Turkish word
  },
  {
    mainWord: "qaşıq",
    relatedWords: ["mətbəx", "yemək", "metal", "alət", "çay"],
    englishWord: "spoon",
    turkishWord: "kaşık", // Added Turkish word
  },
  {
    mainWord: "süd",
    relatedWords: ["içmək", "ağ", "inək", "protein", "kalsium"],
    englishWord: "milk",
    turkishWord: "süt", // Added Turkish word
  },
  {
    mainWord: "göbələk",
    relatedWords: ["meşə", "yemək", "təbiət", "yağış", "torpaq"],
    englishWord: "mushroom",
    turkishWord: "mantar", // Added Turkish word
  },
  {
    mainWord: "rəng",
    relatedWords: ["boya", "çalar", "palitra", "rəssam", "qırmızı"],
    englishWord: "color",
    turkishWord: "renk", // Added Turkish word
  },
  {
    mainWord: "kitab",
    relatedWords: ["oxumaq", "səhifə", "yazı", "müəllif", "bilik"],
    englishWord: "book",
    turkishWord: "kitap", // Added Turkish word
  },
  {
    mainWord: "aslan",
    relatedWords: ["heyvan", "yırtıcı", "şir", "Afrika", "güclü"],
    englishWord: "lion",
    turkishWord: "aslan", // Added Turkish word
  },
  {
    mainWord: "kəsici",
    relatedWords: ["alət", "iti", "bıçaq", "kəsmək", "təhlükəli"],
    englishWord: "cutter",
    turkishWord: "kesici", // Added Turkish word
  },
  {
    mainWord: "şüşə",
    relatedWords: ["şəffaf", "qırılan", "pəncərə", "qab", "bərk"],
    englishWord: "glass",
    turkishWord: "cam", // Added Turkish word
  },
  {
    mainWord: "xurma",
    relatedWords: ["meyvə", "şirin", "ağac", "payız", "qəhvəyi"],
    englishWord: "date",
    turkishWord: "hurma", // Added Turkish word
  },
  {
    mainWord: "dondurma",
    relatedWords: ["şirin", "soyuq", "desert", "yay", "vanil"],
    englishWord: "ice cream",
    turkishWord: "dondurma", // Added Turkish word
  },
  {
    mainWord: "şkaf",
    relatedWords: ["mebel", "paltar", "saxlamaq", "qapı", "rəf"],
    englishWord: "wardrobe",
    turkishWord: "dolap", // Added Turkish word
  },
  {
    mainWord: "qatı",
    relatedWords: ["qalın", "sıx", "bərk", "ağır", "tünd"],
    englishWord: "thick",
    turkishWord: "kalın", // Added Turkish word
  },
  {
    mainWord: "paltar",
    relatedWords: ["geyim", "parça", "moda", "don", "köynək"],
    englishWord: "clothes",
    turkishWord: "elbise", // Added Turkish word
  },
  {
    mainWord: "şalvar",
    relatedWords: ["geyim", "parça", "cins", "ayaq", "kəmər"],
    englishWord: "pants",
    turkishWord: "pantolon", // Added Turkish word
  },
  {
    mainWord: "külək",
    relatedWords: ["hava", "güclü", "əsmək", "soyuq", "fırtına"],
    englishWord: "wind",
    turkishWord: "rüzgar", // Added Turkish word
  },
  {
    mainWord: "zəif",
    relatedWords: ["gücsüz", "arıq", "yorğun", "xəstə", "həssas"],
    englishWord: "weak",
    turkishWord: "zayıf", // Added Turkish word
  },
  {
    mainWord: "köynək",
    relatedWords: ["geyim", "parça", "düymə", "yaxalıq", "qol"],
    englishWord: "shirt",
    turkishWord: "gömlek", // Added Turkish word
  },
  {
    mainWord: "gül",
    relatedWords: ["bitki", "ətir", "rəng", "bağ", "təbiət"],
    englishWord: "flower",
    turkishWord: "çiçek", // Added Turkish word
  },
  {
    mainWord: "dələk",
    relatedWords: ["saç", "qayçı", "kəsmək", "salon", "usta"],
    englishWord: "barber",
    turkishWord: "berber", // Added Turkish word
  },
  {
    mainWord: "çıraq",
    relatedWords: ["işıq", "lampa", "elektrik", "qaranlıq", "yanmaq"],
    englishWord: "lamp",
    turkishWord: "lamba", // Added Turkish word
  },
  {
    mainWord: "paltaryuyan",
    relatedWords: ["maşın", "təmiz", "yumaq", "su", "paltar"],
    englishWord: "washing machine",
    turkishWord: "çamaşır makinesi", // Added Turkish word
  },
  {
    mainWord: "kağız",
    relatedWords: ["yazmaq", "ağ", "vərəq", "qələm", "kitab"],
    englishWord: "paper",
    turkishWord: "kağıt", // Added Turkish word
  },
  {
    mainWord: "müəllim",
    relatedWords: ["məktəb", "dərs", "təhsil", "bilik", "şagird"],
    englishWord: "teacher",
    turkishWord: "öğretmen", // Added Turkish word
  },
  {
    mainWord: "qarpız",
    relatedWords: ["meyvə", "yay", "şirin", "yaşıl", "toxum"],
    englishWord: "watermelon",
    turkishWord: "karpuz", // Added Turkish word
  },
  {
    mainWord: "heyva",
    relatedWords: ["meyvə", "sarı", "turş", "ağac", "payız"],
    englishWord: "quince",
    turkishWord: "ayva", // Added Turkish word
  },
  {
    mainWord: "mandarin",
    relatedWords: ["meyvə", "sitrus", "qış", "dilim", "qabıq"],
    englishWord: "mandarin",
    turkishWord: "mandalina", // Added Turkish word
  },
  {
    mainWord: "erik",
    relatedWords: ["meyvə", "ağac", "turş", "bənövşəyi", "yay"],
    englishWord: "plum",
    turkishWord: "erik", // Added Turkish word
  },
  {
    mainWord: "vişnə",
    relatedWords: ["meyvə", "turş", "qırmızı", "kompot", "mürəbbə"],
    englishWord: "sour cherry",
    turkishWord: "vişne", // Added Turkish word
  },
  {
    mainWord: "cənnət alma",
    relatedWords: ["meyvə", "narıncı", "şirin", "payız", "ağac"],
    englishWord: "persimmon",
    turkishWord: "hurma", // Added Turkish word
  },
  {
    mainWord: "mango",
    relatedWords: ["meyvə", "tropical", "sarı", "şirin", "ekzotik"],
    englishWord: "mango",
    turkishWord: "mango", // Added Turkish word
  },
  {
    mainWord: "avokado",
    relatedWords: ["meyvə", "yaşıl", "yağlı", "salat", "ekzotik"],
    englishWord: "avocado",
    turkishWord: "avokado", // Added Turkish word
  },
  {
    mainWord: "şampan",
    relatedWords: ["içki", "qazlı", "bayram", "şərab", "şüşə"],
    englishWord: "champagne",
    turkishWord: "şampanya", // Added Turkish word
  },
  {
    mainWord: "fındıq",
    relatedWords: ["qərzəkli", "ağac", "qəhvəyi", "ləpə", "meşə"],
    englishWord: "hazelnut",
    turkishWord: "fındık", // Added Turkish word
  },
  {
    mainWord: "alyumini",
    relatedWords: ["metal", "yüngül", "boz", "qab", "folqa"],
    englishWord: "aluminum",
    turkishWord: "alüminyum", // Added Turkish word
  },
  {
    mainWord: "çamadan",
    relatedWords: ["səyahət", "daşımaq", "paltar", "təkər", "dəstək"],
    englishWord: "suitcase",
    turkishWord: "bavul", // Added Turkish word
  },
  {
    mainWord: "zürafə",
    relatedWords: ["heyvan", "uzun", "boyun", "Afrika", "ləkə"],
    englishWord: "giraffe",
    turkishWord: "zürafa", // Added Turkish word
  },
  {
    mainWord: "cırtdan",
    relatedWords: ["nağıl", "balaca", "meşə", "div", "uşaq"],
    englishWord: "dwarf",
    turkishWord: "cüce", // Added Turkish word
  },
  {
    mainWord: "Napoleon Bonaparte",
    relatedWords: ["imperator", "Fransa", "döyüş", "tarix", "sərkərdə"],
    englishWord: "Napoleon Bonaparte",
    turkishWord: "Napolyon Bonapart", // Added Turkish word
  },
  {
    mainWord: "George Washington",
    relatedWords: ["prezident", "Amerika", "azadlıq", "müharibə", "lider"],
    englishWord: "George Washington",
    turkishWord: "George Washington", // Added Turkish word
  },
  {
    mainWord: "Adolf Hitler",
    relatedWords: ["diktator", "müharibə", "Almaniya", "faşizm", "tarix"],
    englishWord: "Adolf Hitler",
    turkishWord: "Adolf Hitler", // Added Turkish word
  },
  {
    mainWord: "Joseph Stalin",
    relatedWords: ["diktator", "SSRİ", "kommunizm", "müharibə", "tarix"],
    englishWord: "Joseph Stalin",
    turkishWord: "Joseph Stalin", // Added Turkish word
  },
  {
    mainWord: "Michael Jackson",
    relatedWords: ["müğənni", "rəqs", "pop", "musiqi", "məşhur"],
    englishWord: "Michael Jackson",
    turkishWord: "Michael Jackson", // Added Turkish word
  },
  {
    mainWord: "William Shakespeare",
    relatedWords: ["yazıçı", "teatr", "dram", "şair", "İngiltərə"],
    englishWord: "William Shakespeare",
    turkishWord: "William Shakespeare", // Added Turkish word
  },
  {
    mainWord: "Steve Jobs",
    relatedWords: ["Apple", "iPhone", "texnologiya", "ixtiraçı", "kompüter"],
    englishWord: "Steve Jobs",
    turkishWord: "Steve Jobs", // Added Turkish word
  },
  {
    mainWord: "Bill Gates",
    relatedWords: [
      "Microsoft",
      "proqram",
      "kompüter",
      "biznesmen",
      "milyarder",
    ],
    englishWord: "Bill Gates",
    turkishWord: "Bill Gates", // Added Turkish word
  },
  {
    mainWord: "Elon Musk",
    relatedWords: ["Tesla", "SpaceX", "texnologiya", "milyarder", "innovasiya"],
    englishWord: "Elon Musk",
    turkishWord: "Elon Musk", // Added Turkish word
  },
  {
    mainWord: "Taylor Swift",
    relatedWords: ["müğənni", "musiqi", "pop", "mahnı", "konsert"],
    englishWord: "Taylor Swift",
    turkishWord: "Taylor Swift", // Added Turkish word
  },
  {
    mainWord: "Cekican",
    relatedWords: ["aktyor", "döyüş", "film", "kung-fu", "Çin"],
    englishWord: "Jackie Chan",
    turkishWord: "Jackie Chan", // Added Turkish word
  },
  {
    mainWord: "Jan Klod Vandam",
    relatedWords: ["aktyor", "döyüş", "film", "idman", "karate"],
    englishWord: "Jean-Claude Van Damme",
    turkishWord: "Jean-Claude Van Damme", // Added Turkish word
  },
  {
    mainWord: "Silvestre Stalone",
    relatedWords: ["aktyor", "Rembo", "Rokki", "film", "döyüş"],
    englishWord: "Sylvester Stallone",
    turkishWord: "Sylvester Stallone", // Added Turkish word
  },
  {
    mainWord: "Nizami-metro",
    relatedWords: ["stansiya", "qatar", "Bakı", "nəqliyyat", "yeraltı"],
    englishWord: "Nizami Metro",
    turkishWord: "Nizami Metro", // Added Turkish word
  },
  {
    mainWord: "Ulduz-metro",
    relatedWords: ["stansiya", "qatar", "Bakı", "nəqliyyat", "yeraltı"],
    englishWord: "Ulduz Metro",
    turkishWord: "Ulduz Metro", // Added Turkish word
  },
  {
    mainWord: "Cristiano Ronaldo",
    relatedWords: ["futbol", "qol", "Real Madrid", "Portuqaliya", "idman"],
    englishWord: "Cristiano Ronaldo",
    turkishWord: "Cristiano Ronaldo", // Added Turkish word
  },
  {
    mainWord: "Lionel Messi",
    relatedWords: ["futbol", "Barcelona", "Argentina", "qol", "idman"],
    englishWord: "Lionel Messi",
    turkishWord: "Lionel Messi", // Added Turkish word
  },
  {
    mainWord: "Usain Bolt",
    relatedWords: ["qaçış", "sprint", "Yamayka", "olimpiya", "rekord"],
    englishWord: "Usain Bolt",
    turkishWord: "Usain Bolt", // Added Turkish word
  },
  {
    mainWord: "Muhammad Ali",
    relatedWords: ["boks", "çempion", "döyüş", "idman", "əfsanə"],
    englishWord: "Muhammad Ali",
    turkishWord: "Muhammad Ali", // Added Turkish word
  },
  {
    mainWord: "Michael Jordan",
    relatedWords: ["basketbol", "NBA", "Chicago Bulls", "idman", "əfsanə"],
    englishWord: "Michael Jordan",
    turkishWord: "Michael Jordan", // Added Turkish word
  },
  {
    mainWord: "LeBron James",
    relatedWords: ["basketbol", "NBA", "Lakers", "idman", "çempion"],
    englishWord: "LeBron James",
    turkishWord: "LeBron James", // Added Turkish word
  },
  {
    mainWord: "Siqaret",
    relatedWords: ["tüstü", "zərərli", "nikotin", "yanmaq", "qadağan"],
    englishWord: "Cigarette",
    turkishWord: "Sigara", // Added Turkish word
  },
  {
    mainWord: "Papirus",
    relatedWords: ["qədim", "yazı", "Misir", "kağız", "tarix"],
    englishWord: "Papyrus",
    turkishWord: "Papirüs", // Added Turkish word
  },
  {
    mainWord: "Kərtənkələ",
    relatedWords: ["sürünən", "quyruq", "günəş", "isti", "həşərat"],
    englishWord: "Lizard",
    turkishWord: "Kertenkele", // Added Turkish word
  },
  {
    mainWord: "Tısbağa",
    relatedWords: ["yavaş", "qabıq", "yaşıl", "su", "uzunömürlü"],
    englishWord: "Turtle",
    turkishWord: "Kaplumbağa", // Added Turkish word
  },
  {
    mainWord: "Divar",
    relatedWords: ["hörmək", "kərpic", "ev", "bina", "sərhəd"],
    englishWord: "Wall",
    turkishWord: "Duvar", // Added Turkish word
  },
  {
    mainWord: "Döşək",
    relatedWords: ["yataq", "yumşaq", "yuxu", "istirahət", "rahat"],
    englishWord: "Mattress",
    turkishWord: "Yatak", // Added Turkish word
  },
  {
    mainWord: "Yolka",
    relatedWords: ["Yeni il", "bəzək", "şam ağacı", "hədiyyə", "bayram"],
    englishWord: "Christmas Tree",
    turkishWord: "Noel Ağacı", // Added Turkish word
  },
  {
    mainWord: "Printer",
    relatedWords: ["çap", "kağız", "mürəkkəb", "kompüter", "ofis"],
    englishWord: "Printer",
    turkishWord: "Yazıcı", // Added Turkish word
  },
  {
    mainWord: "Mount Everest",
    relatedWords: ["dağ", "zirvə", "qar", "Nepal", "alpinist"],
    englishWord: "Mount Everest",
    turkishWord: "Everest Dağı", // Added Turkish word
  },
  {
    mainWord: "Mount Kilimanjaro",
    relatedWords: ["dağ", "Afrika", "vulkan", "Tanzania", "qar"],
    englishWord: "Mount Kilimanjaro",
    turkishWord: "Kilimanjaro Dağı", // Added Turkish word
  },
  {
    mainWord: "Top",
    relatedWords: ["oyun", "idman", "uşaq", "dairəvi", "atmaq"],
    englishWord: "Ball",
    turkishWord: "Top", // Added Turkish word
  },
  {
    mainWord: "Mount Fuji",
    relatedWords: ["dağ", "Yaponiya", "vulkan", "qar", "müqəddəs"],
    englishWord: "Mount Fuji",
    turkishWord: "Fuji Dağı", // Added Turkish word
  },
  {
    mainWord: "Himalayas",
    relatedWords: ["dağ", "sıra", "Asiya", "qar", "Nepal"],
    englishWord: "Himalayas",
    turkishWord: "Himalayalar", // Added Turkish word
  },
  {
    mainWord: "Amazon River",
    relatedWords: ["çay", "meşə", "Braziliya", "tropik", "uzun"],
    englishWord: "Amazon River",
    turkishWord: "Amazon Nehri", // Added Turkish word
  },
  {
    mainWord: "Nile River",
    relatedWords: ["çay", "Misir", "uzun", "qədim", "Afrika"],
    englishWord: "Nile River",
    turkishWord: "Nil Nehri", // Added Turkish word
  },
  {
    mainWord: "Mississippi River",
    relatedWords: ["çay", "Amerika", "uzun", "gəmi", "nəqliyyat"],
    englishWord: "Mississippi River",
    turkishWord: "Mississippi Nehri", // Added Turkish word
  },
  {
    mainWord: "Yangtze River",
    relatedWords: ["çay", "Çin", "uzun", "bənd", "nəqliyyat"],
    englishWord: "Yangtze River",
    turkishWord: "Yangtze Nehri", // Added Turkish word
  },
  {
    mainWord: "Pyramids of Giza",
    relatedWords: ["Misir", "qədim", "firon", "tikili", "səhra"],
    englishWord: "Pyramids of Giza",
    turkishWord: "Giza Piramitleri", // Added Turkish word
  },
  {
    mainWord: "Eiffel Tower",
    relatedWords: ["Paris", "metal", "turizm", "Fransa", "hündür"],
    englishWord: "Eiffel Tower",
    turkishWord: "Eiffel Kulesi", // Added Turkish word
  },
  {
    mainWord: "Ilham Aliyev",
    relatedWords: ["prezident", "Azərbaycan", "siyasət", "lider", "dövlət"],
    englishWord: "Ilham Aliyev",
    turkishWord: "İlham Aliyev", // Added Turkish word
  },
  {
    mainWord: "Big Ben",
    relatedWords: ["London", "saat", "qüllə", "İngiltərə", "parlament"],
    englishWord: "Big Ben",
    turkishWord: "Big Ben", // Added Turkish word
  },
  {
    mainWord: "Taj Mahal",
    relatedWords: ["Hindistan", "məqbərə", "məhəbbət", "memarlıq", "ağ"],
    englishWord: "Taj Mahal",
    turkishWord: "Tac Mahal", // Added Turkish word
  },
  {
    mainWord: "Golden Gate Bridge",
    relatedWords: ["körpü", "San Francisco", "qırmızı", "dəniz", "mühəndislik"],
    englishWord: "Golden Gate Bridge",
    turkishWord: "Altın Kapı Köprüsü", // Added Turkish word
  },
  {
    mainWord: "Louvre Museum",
    relatedWords: ["muzey", "Paris", "incəsənət", "Mona Lisa", "tarix"],
    englishWord: "Louvre Museum",
    turkishWord: "Louvre Müzesi", // Added Turkish word
  },
  {
    mainWord: "Burj Khalifa",
    relatedWords: ["Dubai", "göydələn", "hündür", "memarlıq", "turistik"],
    englishWord: "Burj Khalifa",
    turkishWord: "Burj Khalifa", // Added Turkish word
  },
  {
    mainWord: "Niagara Falls",
    relatedWords: ["şəlalə", "su", "Kanada", "Amerika", "təbiət"],
    englishWord: "Niagara Falls",
    turkishWord: "Niagara Şelaleleri", // Added Turkish word
  },
  {
    mainWord: "Grand Canyon",
    relatedWords: ["dərə", "qaya", "Amerika", "təbiət", "çay"],
    englishWord: "Grand Canyon",
    turkishWord: "Büyük Kanyon", // Added Turkish word
  },
  {
    mainWord: "dəli",
    relatedWords: ["ağılsız", "psixologiya", "xəstə", "qəribə", "davranış"],
    englishWord: "crazy",
    turkishWord: "deli", // Added Turkish word
  },
  {
    mainWord: "Basketball",
    relatedWords: ["idman", "top", "səbət", "komanda", "oyun"],
    englishWord: "Basketball",
    turkishWord: "Basketbol", // Added Turkish word
  },
  {
    mainWord: "Tennis",
    relatedWords: ["idman", "raketka", "top", "kort", "oyun"],
    englishWord: "Tennis",
    turkishWord: "Tenis", // Added Turkish word
  },
  {
    mainWord: "Baseball",
    relatedWords: ["idman", "top", "çubuq", "Amerika", "stadion"],
    englishWord: "Baseball",
    turkishWord: "Beyzbol", // Added Turkish word
  },
  {
    mainWord: "Golf",
    relatedWords: ["idman", "top", "çəmən", "çubuq", "dəlik"],
    englishWord: "Golf",
    turkishWord: "Golf", // Added Turkish word
  },
  {
    mainWord: "Swimming",
    relatedWords: ["üzmək", "su", "hovuz", "idman", "olimpiya"],
    englishWord: "Swimming",
    turkishWord: "Yüzme", // Added Turkish word
  },
  {
    mainWord: "Velosipet",
    relatedWords: ["nəqliyyat", "təkər", "pedal", "sürmək", "idman"],
    englishWord: "Bicycle",
    turkishWord: "Bisiklet", // Added Turkish word
  },
  {
    mainWord: "Boxing",
    relatedWords: ["döyüş", "əlcək", "rinq", "idman", "nokaut"],
    englishWord: "Boxing",
    turkishWord: "Boks", // Added Turkish word
  },
  {
    mainWord: "Volleyball",
    relatedWords: ["idman", "top", "tor", "komanda", "oyun"],
    englishWord: "Volleyball",
    turkishWord: "Voleybol", // Added Turkish word
  },
  {
    mainWord: "Hockey",
    relatedWords: ["idman", "buz", "çubuq", "disk", "komanda"],
    englishWord: "Hockey",
    turkishWord: "Hokey", // Added Turkish word
  },
  {
    mainWord: "Badminton",
    relatedWords: ["idman", "raketka", "volanı", "tor", "oyun"],
    englishWord: "Badminton",
    turkishWord: "Badminton", // Added Turkish word
  },
  {
    mainWord: "Surfing",
    relatedWords: ["dalğa", "dəniz", "lövhə", "idman", "çimərlik"],
    englishWord: "Surfing",
    turkishWord: "Sörf", // Added Turkish word
  },
  {
    mainWord: "Karate",
    relatedWords: ["döyüş", "idman", "kəmər", "Yaponiya", "təlim"],
    englishWord: "Karate",
    turkishWord: "Karate", // Added Turkish word
  },
  {
    mainWord: "Wrestling",
    relatedWords: ["güləş", "idman", "döyüş", "ring", "yarış"],
    englishWord: "Wrestling",
    turkishWord: "Güreş", // Added Turkish word
  },
  {
    mainWord: "Australia",
    relatedWords: ["qitə", "kənquru", "Sidney", "okean", "səhra"],
    englishWord: "Australia",
    turkishWord: "Avustralya", // Added Turkish word
  },
  {
    mainWord: "Brazil",
    relatedWords: ["ölkə", "futbol", "karnaval", "Amazon", "Rio"],
    englishWord: "Brazil",
    turkishWord: "Brezilya", // Added Turkish word
  },
  {
    mainWord: "Canada",
    relatedWords: ["ölkə", "klenot", "qış", "xokkey", "Toronto"],
    englishWord: "Canada",
    turkishWord: "Kanada", // Added Turkish word
  },
  {
    mainWord: "Egypt",
    relatedWords: ["piramida", "firon", "Nil", "səhra", "qədim"],
    englishWord: "Egypt",
    turkishWord: "Mısır", // Added Turkish word
  },
  {
    mainWord: "France",
    relatedWords: ["Paris", "Eyfel", "şərab", "moda", "incəsənət"],
    englishWord: "France",
    turkishWord: "Fransa", // Added Turkish word
  },
  {
    mainWord: "Germany",
    relatedWords: ["Berlin", "avtomobil", "pivə", "texnologiya", "futbol"],
    englishWord: "Germany",
    turkishWord: "Almanya", // Added Turkish word
  },
  {
    mainWord: "India",
    relatedWords: ["ədviyyat", "film", "çay", "fil", "Tac Mahal"],
    englishWord: "India",
    turkishWord: "Hindistan", // Added Turkish word
  },
  {
    mainWord: "Japan",
    relatedWords: ["samuray", "suşi", "texnologiya", "sakura", "anime"],
    englishWord: "Japan",
    turkishWord: "Japonya", // Added Turkish word
  },
  {
    mainWord: "Kenya",
    relatedWords: ["Afrika", "safari", "fil", "savanna", "qaçış"],
    englishWord: "Kenya",
    turkishWord: "Kenya", // Added Turkish word
  },
  {
    mainWord: "Luxembourg",
    relatedWords: ["Avropa", "kiçik", "bank", "varlı", "dövlət"],
    englishWord: "Luxembourg",
    turkishWord: "Lüksemburg", // Added Turkish word
  },
  {
    mainWord: "Mexico",
    relatedWords: ["takos", "səhra", "piramida", "sombrero", "salsa"],
    englishWord: "Mexico",
    turkishWord: "Meksika", // Added Turkish word
  },
  {
    mainWord: "Norway",
    relatedWords: ["fiyord", "soyuq", "balıq", "Viking", "şimal"],
    englishWord: "Norway",
    turkishWord: "Norveç", // Added Turkish word
  },
  {
    mainWord: "Portugal",
    relatedWords: ["okean", "şərab", "futbol", "dəniz", "Ronaldo"],
    englishWord: "Portugal",
    turkishWord: "Portekiz", // Added Turkish word
  },
  {
    mainWord: "Qatar",
    relatedWords: ["səhra", "neft", "ərəb", "varlı", "isti"],
    englishWord: "Qatar",
    turkishWord: "Katar", // Added Turkish word
  },
  {
    mainWord: "Russia",
    relatedWords: ["böyük", "soyuq", "Kreml", "qar", "vodka"],
    englishWord: "Russia",
    turkishWord: "Rusya", // Added Turkish word
  },
  {
    mainWord: "Bayram Nurlu",
    relatedWords: ["müğənni", "meyxana", "Bakı", "mahnı", "şou"],
    englishWord: "Bayram Nurlu",
    turkishWord: "Bayram Nurlu", // Added Turkish word
  },
  {
    mainWord: "Samiraldo",
    relatedWords: ["bloger", "video", "YouTube", "məşhur", "sosial"],
    englishWord: "Samiraldo",
    turkishWord: "Samiraldo", // Added Turkish word
  },
  {
    mainWord: "Hacı Nuran",
    relatedWords: ["müğənni", "mahnı", "konsert", "səs", "məşhur"],
    englishWord: "Haji Nuran",
    turkishWord: "Hacı Nuran", // Added Turkish word
  },
  {
    mainWord: "Elza Seyidcahan",
    relatedWords: ["müğənni", "şairə", "mahnı", "şeir", "məşhur"],
    englishWord: "Elza Seyidcahan",
    turkishWord: "Elza Seyidcahan", // Added Turkish word
  },
  {
    mainWord: "Spain",
    relatedWords: ["flamenko", "paella", "öküz", "Madrid", "fiesta"],
    englishWord: "Spain",
    turkishWord: "İspanya", // Added Turkish word
  },
  {
    mainWord: "Turkey",
    relatedWords: ["İstanbul", "kebab", "çay", "bazar", "məscid"],
    englishWord: "Turkey",
    turkishWord: "Türkiye", // Added Turkish word
  },
  {
    mainWord: "Ram",
    relatedWords: ["kompüter", "yaddaş", "sürət", "GB", "prosessor"],
    englishWord: "Ram",
    turkishWord: "Ram", // Added Turkish word
  },
  {
    mainWord: "Processor",
    relatedWords: ["kompüter", "CPU", "sürət", "hesablama", "çip"],
    englishWord: "Processor",
    turkishWord: "İşlemci", // Added Turkish word
  },
  {
    mainWord: "Avtobus",
    relatedWords: ["nəqliyyat", "sərnişin", "sürücü", "marşrut", "dayanacaq"],
    englishWord: "Bus",
    turkishWord: "Otobüs", // Added Turkish word
  },
  {
    mainWord: "Soğan",
    relatedWords: ["tərəvəz", "acı", "ağlamaq", "mətbəx", "yemək"],
    englishWord: "Onion",
    turkishWord: "Soğan", // Added Turkish word
  },
  {
    mainWord: "Çörək",
    relatedWords: ["un", "duz", "təndır", "isti", "yemək"],
    englishWord: "Bread",
    turkishWord: "Ekmek", // Added Turkish word
  },
  {
    mainWord: "Kapital Bank",
    relatedWords: ["pul", "kart", "kredit", "filial", "bank"],
    englishWord: "Kapital Bank",
    turkishWord: "Kapital Bank", // Added Turkish word
  },
  {
    mainWord: "Pasha Bank",
    relatedWords: ["pul", "kart", "kredit", "filial", "bank"],
    englishWord: "Pasha Bank",
    turkishWord: "Pasha Bank", // Added Turkish word
  },
  {
    mainWord: "Sarımsaq",
    relatedWords: ["tərəvəz", "iy", "mətbəx", "ədviyyat", "sağlamlıq"],
    englishWord: "Garlic",
    turkishWord: "Sarımsak", // Added Turkish word
  },
  {
    mainWord: "səhər",
    relatedWords: ["günəş", "oyanmaq", "sərin", "başlanğıc", "çay"],
    englishWord: "morning",
    turkishWord: "sabah", // Added Turkish word
  },
  {
    mainWord: "günorta",
    relatedWords: ["nahar", "günəş", "isti", "fasilə", "yemək"],
    englishWord: "noon",
    turkishWord: "öğle", // Added Turkish word
  },
  {
    mainWord: "axşam",
    relatedWords: ["gün batımı", "qaranlıq", "şam", "dincəlmək", "ev"],
    englishWord: "evening",
    turkishWord: "akşam", // Added Turkish word
  },
  {
    mainWord: "gecə",
    relatedWords: ["qaranlıq", "ay", "ulduz", "yatmaq", "yuxu"],
    englishWord: "night",
    turkishWord: "gece", // Added Turkish word
  },
  {
    mainWord: "saat",
    relatedWords: ["zaman", "əqrəb", "vaxt", "dəqiqə", "qol"],
    englishWord: "hour",
    turkishWord: "saat", // Added Turkish word
  },
  {
    mainWord: "dəqiqə",
    relatedWords: ["zaman", "saat", "vaxt", "saniyə", "gözləmək"],
    englishWord: "minute",
    turkishWord: "dakika", // Added Turkish word
  },
  {
    mainWord: "saniyə",
    relatedWords: ["zaman", "qısa", "saat", "dəqiqə", "an"],
    englishWord: "second",
    turkishWord: "saniye", // Added Turkish word
  },
  {
    mainWord: "gün",
    relatedWords: ["24 saat", "səhər", "axşam", "vaxt", "həftə"],
    englishWord: "day",
    turkishWord: "gün", // Added Turkish word
  },
  {
    mainWord: "həftə",
    relatedWords: ["7 gün", "bazar", "iş", "vaxt", "ay"],
    englishWord: "week",
    turkishWord: "hafta", // Added Turkish word
  },
  {
    mainWord: "ay",
    relatedWords: ["30 gün", "təqvim", "vaxt", "il", "fəsil"],
    englishWord: "month",
    turkishWord: "ay", // Added Turkish word
  },
  {
    mainWord: "il",
    relatedWords: ["365 gün", "təqvim", "vaxt", "ay", "dövr"],
    englishWord: "year",
    turkishWord: "yıl", // Added Turkish word
  },
  {
    mainWord: "otaq",
    relatedWords: ["mənzil", "qapı", "pəncərə", "mebel", "divar"],
    englishWord: "room",
    turkishWord: "oda", // Added Turkish word
  },
  {
    mainWord: "yataq",
    relatedWords: ["yuxu", "yorğan", "döşək", "yastıq", "dincəlmək"],
    englishWord: "bed",
    turkishWord: "yatak", // Added Turkish word
  },
  {
    mainWord: "mətbəx",
    relatedWords: ["yemək", "qab", "soyuducu", "plitə", "masa"],
    englishWord: "kitchen",
    turkishWord: "mutfak", // Added Turkish word
  },
  {
    mainWord: "hamam",
    relatedWords: ["su", "duş", "təmizlik", "güzgü", "sabun"],
    englishWord: "bathroom",
    turkishWord: "banyo", // Added Turkish word
  },
  {
    mainWord: "qapı",
    relatedWords: ["ağac", "dəmir", "açar", "qıfıl", "dəstək"],
    englishWord: "door",
    turkishWord: "kapı", // Added Turkish word
  },
  {
    mainWord: "pəncərə",
    relatedWords: ["şüşə", "işıq", "hava", "mənzərə", "pərdə"],
    englishWord: "window",
    turkishWord: "pencere", // Added Turkish word
  },
  {
    mainWord: "masa",
    relatedWords: ["mebel", "ağac", "stul", "yemək", "iş"],
    englishWord: "table",
    turkishWord: "masa", // Added Turkish word
  },
  {
    mainWord: "stul",
    relatedWords: ["mebel", "oturmaq", "ağac", "masa", "ayaq"],
    englishWord: "chair",
    turkishWord: "sandalye", // Added Turkish word
  },
  {
    mainWord: "yemək",
    relatedWords: ["qida", "mətbəx", "dad", "ac", "hazırlamaq"],
    englishWord: "food",
    turkishWord: "yemek", // Added Turkish word
  },
  {
    mainWord: "içmək",
    relatedWords: ["su", "maye", "susuzluq", "sərin", "stəkan"],
    englishWord: "drink",
    turkishWord: "içmek", // Added Turkish word
  },
  {
    mainWord: "qəhvə",
    relatedWords: ["içki", "isti", "kofe", "fincan", "səhər"],
    englishWord: "coffee",
    turkishWord: "kahve", // Added Turkish word
  },
  {
    mainWord: "su",
    relatedWords: ["maye", "içmək", "təmiz", "həyat", "sərin"],
    englishWord: "water",
    turkishWord: "su", // Added Turkish word
  },
  {
    mainWord: "meyvə",
    relatedWords: ["şirin", "vitamin", "təzə", "bağ", "ağac"],
    englishWord: "fruit",
    turkishWord: "meyve", // Added Turkish word
  },
  {
    mainWord: "tərəvəz",
    relatedWords: ["vitamin", "bağ", "təzə", "sağlam", "yemək"],
    englishWord: "vegetable",
    turkishWord: "sebze", // Added Turkish word
  },
  {
    mainWord: "ət",
    relatedWords: ["heyvan", "protein", "yemək", "qızartmaq", "bişirmək"],
    englishWord: "meat",
    turkishWord: "et", // Added Turkish word
  },
  {
    mainWord: "düyü",
    relatedWords: ["plov", "dən", "ağ", "yemək", "bişirmək"],
    englishWord: "rice",
    turkishWord: "pirinç", // Added Turkish word
  },
  {
    mainWord: "makaron",
    relatedWords: ["un", "yemək", "İtaliya", "bişirmək", "sous"],
    englishWord: "pasta",
    turkishWord: "makarna", // Added Turkish word
  },
  {
    mainWord: "yumurta",
    relatedWords: ["toyuq", "protein", "sarı", "qızartmaq", "bişirmək"],
    englishWord: "egg",
    turkishWord: "yumurta", // Added Turkish word
  },
  {
    mainWord: "pendir",
    relatedWords: ["süd", "ağ", "duzlu", "protein", "səhər"],
    englishWord: "cheese",
    turkishWord: "peynir", // Added Turkish word
  },
  {
    mainWord: "yağ",
    relatedWords: ["süd", "sarı", "ərimək", "qızartmaq", "yemək"],
    englishWord: "butter",
    turkishWord: "tereyağı", // Added Turkish word
  },
  {
    mainWord: "duz",
    relatedWords: ["ağ", "dad", "mineral", "dəniz", "mətbəx"],
    englishWord: "salt",
    turkishWord: "tuz", // Added Turkish word
  },
  {
    mainWord: "şəkər",
    relatedWords: ["şirin", "ağ", "çay", "desert", "dad"],
    englishWord: "sugar",
    turkishWord: "şeker", // Added Turkish word
  },
  {
    mainWord: "dərman",
    relatedWords: ["xəstəlik", "müalicə", "həb", "aptek", "sağlamlıq"],
    englishWord: "medicine",
    turkishWord: "ilaç", // Added Turkish word
  },
  {
    mainWord: "həkim",
    relatedWords: ["xəstəxana", "müalicə", "sağlamlıq", "tibb", "resept"],
    englishWord: "doctor",
    turkishWord: "doktor", // Added Turkish word
  },
  {
    mainWord: "xəstəxana",
    relatedWords: ["həkim", "müalicə", "tibb", "xəstə", "palata"],
    englishWord: "hospital",
    turkishWord: "hastane", // Added Turkish word
  },
  {
    mainWord: "aptek",
    relatedWords: ["dərman", "həb", "resept", "sağlamlıq", "satış"],
    englishWord: "pharmacy",
    turkishWord: "eczane", // Added Turkish word
  },
  {
    mainWord: "maşın",
    relatedWords: ["nəqliyyat", "sürücü", "təkər", "mühərrik", "yol"],
    englishWord: "car",
    turkishWord: "araba", // Added Turkish word
  },
  {
    mainWord: "taksi",
    relatedWords: ["maşın", "sürücü", "sərnişin", "pul", "yol"],
    englishWord: "taxi",
    turkishWord: "taksi", // Added Turkish word
  },
  {
    mainWord: "dəmir yolu",
    relatedWords: ["qatar", "vaqon", "stansiya", "bilet", "səyahət"],
    englishWord: "railway",
    turkishWord: "demiryolu", // Added Turkish word
  },
  {
    mainWord: "aeroport",
    relatedWords: ["təyyarə", "uçuş", "səyahət", "bilet", "çanta"],
    englishWord: "airport",
    turkishWord: "havaalanı", // Added Turkish word
  },
  {
    mainWord: "təyyarə",
    relatedWords: ["uçmaq", "səma", "pilot", "qanad", "səyahət"],
    englishWord: "airplane",
    turkishWord: "uçak", // Added Turkish word
  },
  {
    mainWord: "bilet",
    relatedWords: ["səyahət", "pul", "nəqliyyat", "rezervasiya", "kassir"],
    englishWord: "ticket",
    turkishWord: "bilet", // Added Turkish word
  },
  {
    mainWord: "pul",
    relatedWords: ["manat", "bank", "alış-veriş", "ödəniş", "var-dövlət"],
    englishWord: "money",
    turkishWord: "para", // Added Turkish word
  },
  {
    mainWord: "bank",
    relatedWords: ["pul", "kredit", "hesab", "kart", "əmanət"],
    englishWord: "bank",
    turkishWord: "banka", // Added Turkish word
  },
  {
    mainWord: "kart",
    relatedWords: ["bank", "pul", "ödəniş", "plastik", "PIN"],
    englishWord: "card",
    turkishWord: "kart", // Added Turkish word
  },
  {
    mainWord: "dükkan",
    relatedWords: ["alış-veriş", "satıcı", "mal", "pul", "mağaza"],
    englishWord: "shop",
    turkishWord: "dükkan", // Added Turkish word
  },
  {
    mainWord: "bazar",
    relatedWords: ["alış-veriş", "qiymət", "meyvə", "tərəvəz", "satıcı"],
    englishWord: "market",
    turkishWord: "pazar", // Added Turkish word
  },
  {
    mainWord: "səbət",
    relatedWords: ["alış-veriş", "daşımaq", "ərzaq", "tutmaq", "market"],
    englishWord: "basket",
    turkishWord: "sepet", // Added Turkish word
  },
  {
    mainWord: "güzgü",
    relatedWords: ["əks", "şüşə", "baxmaq", "divar", "hamam"],
    englishWord: "mirror",
    turkishWord: "ayna", // Added Turkish word
  },
  {
    mainWord: "sabun",
    relatedWords: ["təmizlik", "köpük", "yumaq", "hamam", "ətir"],
    englishWord: "soap",
    turkishWord: "sabun", // Added Turkish word
  },
  {
    mainWord: "şampun",
    relatedWords: ["saç", "yumaq", "təmizlik", "hamam", "köpük"],
    englishWord: "shampoo",
    turkishWord: "şampuan", // Added Turkish word
  },
  {
    mainWord: "diş pastası",
    relatedWords: ["diş", "təmizlik", "fırça", "ağız", "səhər"],
    englishWord: "toothpaste",
    turkishWord: "diş macunu", // Added Turkish word
  },
  {
    mainWord: "diş fırçası",
    relatedWords: ["diş", "təmizlik", "pasta", "ağız", "səhər"],
    englishWord: "toothbrush",
    turkishWord: "diş fırçası", // Added Turkish word
  },
  {
    mainWord: "dəsmal",
    relatedWords: ["parça", "qurulamaq", "hamam", "təmiz", "yumşaq"],
    englishWord: "towel",
    turkishWord: "havlu", // Added Turkish word
  },
  {
    mainWord: "kələm dolması",
    relatedWords: ["yemək", "mətbəx", "ət", "kələm", "milli"],
    englishWord: "stuffed cabbage",
    turkishWord: "lahana dolması", // Added Turkish word
  },
  {
    mainWord: "corab",
    relatedWords: ["geyim", "ayaq", "parça", "isti", "yun"],
    englishWord: "socks",
    turkishWord: "çorap", // Added Turkish word
  },
  {
    mainWord: "ayaqqabı",
    relatedWords: ["geyim", "ayaq", "dəri", "bağ", "rahat"],
    englishWord: "shoes",
    turkishWord: "ayakkabı", // Added Turkish word
  },
  {
    mainWord: "gözlük",
    relatedWords: ["göz", "görmək", "şüşə", "çərçivə", "optika"],
    englishWord: "glasses",
    turkishWord: "gözlük", // Added Turkish word
  },
  {
    mainWord: "çətir",
    relatedWords: ["yağış", "qorumaq", "açmaq", "su", "külək"],
    englishWord: "umbrella",
    turkishWord: "şemsiye", // Added Turkish word
  },
  {
    mainWord: "çanta",
    relatedWords: ["daşımaq", "əşya", "dəri", "qulp", "məktəb"],
    englishWord: "bag",
    turkishWord: "çanta", // Added Turkish word
  },
  {
    mainWord: "qayğanaq",
    relatedWords: ["yumurta", "səhər", "qızartmaq", "yağ", "yemək"],
    englishWord: "omelet",
    turkishWord: "omlet", // Added Turkish word
  },
  {
    mainWord: "öpüş",
    relatedWords: ["sevgi", "məhəbbət", "dodaq", "romantik", "hiss"],
    englishWord: "kiss",
    turkishWord: "öpücük", // Added Turkish word
  },
  {
    mainWord: "sevgi",
    relatedWords: ["məhəbbət", "hiss", "ürək", "romantik", "xoşbəxtlik"],
    englishWord: "love",
    turkishWord: "aşk", // Added Turkish word
  },
  {
    mainWord: "televizor",
    relatedWords: ["ekran", "kanal", "veriliş", "film", "xəbər"],
    englishWord: "television",
    turkishWord: "televizyon", // Added Turkish word
  },
  {
    mainWord: "kamera",
    relatedWords: ["şəkil", "video", "çəkiliş", "yaddaş", "obyektiv"],
    englishWord: "camera",
    turkishWord: "kamera", // Added Turkish word
  },
  {
    mainWord: "foto",
    relatedWords: ["şəkil", "xatirə", "albom", "kamera", "çəkmək"],
    englishWord: "photo",
    turkishWord: "fotoğraf", // Added Turkish word
  },
  {
    mainWord: "məktub",
    relatedWords: ["yazı", "kağız", "poçt", "zərf", "göndərmək"],
    englishWord: "letter",
    turkishWord: "mektup", // Added Turkish word
  },
  {
    mainWord: "jurnal",
    relatedWords: ["nəşr", "məqalə", "şəkil", "oxumaq", "həftəlik"],
    englishWord: "magazine",
    turkishWord: "dergi", // Added Turkish word
  },
  {
    mainWord: "qəzet",
    relatedWords: ["xəbər", "məqalə", "gündəlik", "oxumaq", "mətbuat"],
    englishWord: "newspaper",
    turkishWord: "gazete", // Added Turkish word
  },
  // ... existing code ...
  {
    mainWord: "qələm",
    relatedWords: ["yazmaq", "mürəkkəb", "dəftər", "məktəb", "alət"],
    englishWord: "pen",
    turkishWord: "kalem", // Added Turkish word
  },
  {
    mainWord: "kompüter",
    relatedWords: ["texnologiya", "internet", "proqram", "yaddaş", "ekran"],
    englishWord: "computer",
    turkishWord: "bilgisayar", // Added Turkish word
  },
  {
    mainWord: "telefon",
    relatedWords: ["zəng", "mobil", "söhbət", "mesaj", "cihaz"],
    englishWord: "phone",
    turkishWord: "telefon", // Added Turkish word
  },
  {
    mainWord: "internet",
    relatedWords: ["şəbəkə", "onlayn", "bilik", "sürət", "texnologiya"],
    englishWord: "internet",
    turkishWord: "internet", // Added Turkish word
  },
  {
    mainWord: "proqram",
    relatedWords: ["kompüter", "yazılım", "kod", "işlətmək", "funksiya"],
    englishWord: "software",
    turkishWord: "yazılım", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["kino", "ekran", "aktyor", "süjet", "izləmək"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "mahnı",
    relatedWords: ["musiqi", "söz", "melodiya", "səsləndirmək", "səhnə"],
    englishWord: "song",
    turkishWord: "şarkı", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["hərəkət", "musiqi", "səhnə", "ritm", "əyləncə"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["ədəbiyyat", "söz", "qafiyə", "hiss", "yazı"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["səhnə", "aktyor", "pərdə", "drama", "izləmək"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "muzey",
    relatedWords: ["tarix", "incəsənət", "ekspozisiya", "bilik", "ziyarət"],
    englishWord: "museum",
    turkishWord: "müze", // Added Turkish word
  },
  {
    mainWord: "park",
    relatedWords: ["təbiət", "ağac", "gəzinti", "əyləncə", "uşaq"],
    englishWord: "park",
    turkishWord: "park", // Added Turkish word
  },
  {
    mainWord: "bağ",
    relatedWords: ["bitki", "çiçək", "ağac", "təbiət", "gözəllik"],
    englishWord: "garden",
    turkishWord: "bahçe", // Added Turkish word
  },
  {
    mainWord: "çimərlik",
    relatedWords: ["qum", "dəniz", "günəş", "yay", "istirahət"],
    englishWord: "beach",
    turkishWord: "plaj", // Added Turkish word
  },
  {
    mainWord: "dağ",
    relatedWords: ["zirvə", "təbiət", "yürüyüş", "qaya", "hündür"],
    englishWord: "mountain",
    turkishWord: "dağ", // Added Turkish word
  },
  {
    mainWord: "meşə",
    relatedWords: ["ağac", "təbiət", "heyvan", "yaşıl", "sakitlik"],
    englishWord: "forest",
    turkishWord: "orman", // Added Turkish word
  },
  {
    mainWord: "çay",
    relatedWords: ["su", "axın", "təbiət", "balıq", "körpü"],
    englishWord: "river",
    turkishWord: "nehir", // Added Turkish word
  },
  {
    mainWord: "göl",
    relatedWords: ["su", "təbiət", "balıq", "gəmi", "sakitlik"],
    englishWord: "lake",
    turkishWord: "göl", // Added Turkish word
  },
  {
    mainWord: "dəniz",
    relatedWords: ["su", "gəmi", "balıq", "çimərlik", "təbiət"],
    englishWord: "sea",
    turkishWord: "deniz", // Added Turkish word
  },
  {
    mainWord: "okean",
    relatedWords: ["su", "gəmi", "balıq", "təbiət", "böyük"],
    englishWord: "ocean",
    turkishWord: "okyanus", // Added Turkish word
  },
  {
    mainWord: "hava",
    relatedWords: ["günəş", "yağış", "külək", "bulud", "təbiət"],
    englishWord: "weather",
    turkishWord: "hava", // Added Turkish word
  },
  {
    mainWord: "günəş",
    relatedWords: ["işıq", "isti", "göy", "yay", "təbiət"],
    englishWord: "sun",
    turkishWord: "güneş", // Added Turkish word
  },
  {
    mainWord: "ay",
    relatedWords: ["göy", "gecə", "işıq", "ulduz", "təbiət"],
    englishWord: "moon",
    turkishWord: "ay", // Added Turkish word
  },
  {
    mainWord: "ulduz",
    relatedWords: ["göy", "gecə", "işıq", "təbiət", "parlaq"],
    englishWord: "star",
    turkishWord: "yıldız", // Added Turkish word
  },
  {
    mainWord: "bulud",
    relatedWords: ["göy", "yağış", "ağ", "təbiət", "hava"],
    englishWord: "cloud",
    turkishWord: "bulut", // Added Turkish word
  },
  {
    mainWord: "yağış",
    relatedWords: ["su", "bulud", "təbiət", "hava", "yaş"],
    englishWord: "rain",
    turkishWord: "yağmur", // Added Turkish word
  },
  {
    mainWord: "qar",
    relatedWords: ["ağ", "soyuq", "qış", "təbiət", "hava"],
    englishWord: "snow",
    turkishWord: "kar", // Added Turkish word
  },
  {
    mainWord: "külək",
    relatedWords: ["hava", "güclü", "əsmək", "soyuq", "fırtına"],
    englishWord: "wind",
    turkishWord: "rüzgar", // Added Turkish word
  },
  {
    mainWord: "fırtına",
    relatedWords: ["hava", "külək", "yağış", "təbiət", "güclü"],
    englishWord: "storm",
    turkishWord: "fırtına", // Added Turkish word
  },
  {
    mainWord: "zəlzələ",
    relatedWords: ["təbiət", "yer", "sarsıntı", "təhlükə", "fəlakət"],
    englishWord: "earthquake",
    turkishWord: "deprem", // Added Turkish word
  },
  {
    mainWord: "vulkan",
    relatedWords: ["təbiət", "dağ", "lava", "təhlükə", "fəlakət"],
    englishWord: "volcano",
    turkishWord: "volkan", // Added Turkish word
  },
  {
    mainWord: "yanğın",
    relatedWords: ["od", "təhlükə", "təbiət", "fəlakət", "yanmaq"],
    englishWord: "fire",
    turkishWord: "yangın", // Added Turkish word
  },
  {
    mainWord: "sel",
    relatedWords: ["su", "təbiət", "təhlükə", "fəlakət", "yağış"],
    englishWord: "flood",
    turkishWord: "sel", // Added Turkish word
  },
  {
    mainWord: "quraqlıq",
    relatedWords: ["təbiət", "su", "təhlükə", "fəlakət", "hava"],
    englishWord: "drought",
    turkishWord: "kuraklık", // Added Turkish word
  },
  {
    mainWord: "təbiət",
    relatedWords: ["ağac", "heyvan", "hava", "gözəllik", "sakitlik"],
    englishWord: "nature",
    turkishWord: "doğa", // Added Turkish word
  },
  {
    mainWord: "heyvan",
    relatedWords: ["təbiət", "canlı", "meşə", "çöl", "sakitlik"],
    englishWord: "animal",
    turkishWord: "hayvan", // Added Turkish word
  },
  {
    mainWord: "bitki",
    relatedWords: ["təbiət", "yaşıl", "çiçək", "ağac", "bağ"],
    englishWord: "plant",
    turkishWord: "bitki", // Added Turkish word
  },
  {
    mainWord: "çiçək",
    relatedWords: ["bitki", "rəng", "ətir", "bağ", "gözəllik"],
    englishWord: "flower",
    turkishWord: "çiçek", // Added Turkish word
  },
  {
    mainWord: "ağac",
    relatedWords: ["bitki", "yaşıl", "meşə", "təbiət", "gözəllik"],
    englishWord: "tree",
    turkishWord: "ağaç", // Added Turkish word
  },
  {
    mainWord: "yarpaq",
    relatedWords: ["bitki", "yaşıl", "ağac", "təbiət", "gözəllik"],
    englishWord: "leaf",
    turkishWord: "yaprak", // Added Turkish word
  },
  {
    mainWord: "kök",
    relatedWords: ["bitki", "torpaq", "təbiət", "gözəllik", "yaşıl"],
    englishWord: "root",
    turkishWord: "kök", // Added Turkish word
  },
  {
    mainWord: "torpaq",
    relatedWords: ["təbiət", "bitki", "ağac", "gözəllik", "yaşıl"],
    englishWord: "soil",
    turkishWord: "toprak", // Added Turkish word
  },
  {
    mainWord: "daş",
    relatedWords: ["təbiət", "bərk", "qaya", "gözəllik", "sakitlik"],
    englishWord: "rock",
    turkishWord: "taş", // Added Turkish word
  },
  {
    mainWord: "qaya",
    relatedWords: ["təbiət", "bərk", "daş", "gözəllik", "sakitlik"],
    englishWord: "cliff",
    turkishWord: "kaya", // Added Turkish word
  },
  {
    mainWord: "qum",
    relatedWords: ["təbiət", "çimərlik", "dəniz", "gözəllik", "sakitlik"],
    englishWord: "sand",
    turkishWord: "kum", // Added Turkish word
  },
  {
    mainWord: "torpaq",
    relatedWords: ["təbiət", "bitki", "ağac", "gözəllik", "yaşıl"],
    englishWord: "land",
    turkishWord: "arazi", // Added Turkish word
  },
  {
    mainWord: "səma",
    relatedWords: ["təbiət", "göy", "gözəllik", "sakitlik", "hava"],
    englishWord: "sky",
    turkishWord: "gökyüzü", // Added Turkish word
  },
  {
    mainWord: "göy",
    relatedWords: ["təbiət", "səma", "gözəllik", "sakitlik", "hava"],
    englishWord: "blue",
    turkishWord: "mavi", // Added Turkish word
  },
  {
    mainWord: "yaşıl",
    relatedWords: ["təbiət", "bitki", "ağac", "gözəllik", "sakitlik"],
    englishWord: "green",
    turkishWord: "yeşil", // Added Turkish word
  },
  {
    mainWord: "qırmızı",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "red",
    turkishWord: "kırmızı", // Added Turkish word
  },
  {
    mainWord: "sarı",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "yellow",
    turkishWord: "sarı", // Added Turkish word
  },
  {
    mainWord: "ağ",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "white",
    turkishWord: "beyaz", // Added Turkish word
  },
  {
    mainWord: "qara",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "black",
    turkishWord: "siyah", // Added Turkish word
  },
  {
    mainWord: "bənövşəyi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "purple",
    turkishWord: "mor", // Added Turkish word
  },
  {
    mainWord: "narıncı",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "orange",
    turkishWord: "turuncu", // Added Turkish word
  },
  {
    mainWord: "çəhrayı",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "pink",
    turkishWord: "pembe", // Added Turkish word
  },
  {
    mainWord: "boz",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "gray",
    turkishWord: "gri", // Added Turkish word
  },
  {
    mainWord: "qəhvəyi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "brown",
    turkishWord: "kahverengi", // Added Turkish word
  },
  {
    mainWord: "qızıl",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "gold",
    turkishWord: "altın", // Added Turkish word
  },
  {
    mainWord: "gümüş",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "silver",
    turkishWord: "gümüş", // Added Turkish word
  },
  {
    mainWord: "rəng",
    relatedWords: ["çiçək", "gözəllik", "sakitlik", "təbiət", "palitra"],
    englishWord: "color",
    turkishWord: "renk", // Added Turkish word
  },
  {
    mainWord: "palitra",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "palette",
    turkishWord: "palet", // Added Turkish word
  },
  {
    mainWord: "rəssam",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "artist",
    turkishWord: "sanatçı", // Added Turkish word
  },
  {
    mainWord: "sənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "melodiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "melody",
    turkishWord: "melodi", // Added Turkish word
  },
  {
    mainWord: "səs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "sound",
    turkishWord: "ses", // Added Turkish word
  },
  {
    mainWord: "not",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "note",
    turkishWord: "nota", // Added Turkish word
  },
  {
    mainWord: "alət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "instrument",
    turkishWord: "enstrüman", // Added Turkish word
  },
  {
    mainWord: "səhnə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "stage",
    turkishWord: "sahne", // Added Turkish word
  },
  {
    mainWord: "aktyor",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "actor",
    turkishWord: "aktör", // Added Turkish word
  },
  {
    mainWord: "drama",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "drama",
    turkishWord: "drama", // Added Turkish word
  },
  {
    mainWord: "komediya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "comedy",
    turkishWord: "komedi", // Added Turkish word
  },
  {
    mainWord: "tragediya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "tragedy",
    turkishWord: "trajedi", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "elm",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "science",
    turkishWord: "bilim", // Added Turkish word
  },
  {
    mainWord: "fizika",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "physics",
    turkishWord: "fizik", // Added Turkish word
  },
  {
    mainWord: "kimya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "chemistry",
    turkishWord: "kimya", // Added Turkish word
  },
  {
    mainWord: "riyaziyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "mathematics",
    turkishWord: "matematik", // Added Turkish word
  },
  {
    mainWord: "biologiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "biology",
    turkishWord: "biyoloji", // Added Turkish word
  },
  {
    mainWord: "astronomiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "astronomy",
    turkishWord: "astronomi", // Added Turkish word
  },
  {
    mainWord: "geologiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "geology",
    turkishWord: "jeoloji", // Added Turkish word
  },
  {
    mainWord: "psixologiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "psychology",
    turkishWord: "psikoloji", // Added Turkish word
  },
  {
    mainWord: "sosiologiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "sociology",
    turkishWord: "sosyoloji", // Added Turkish word
  },
  {
    mainWord: "filosofiya",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "philosophy",
    turkishWord: "felsefe", // Added Turkish word
  },
  {
    mainWord: "dil",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "language",
    turkishWord: "dil", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
  {
    mainWord: "hekayə",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "story",
    turkishWord: "hikaye", // Added Turkish word
  },
  {
    mainWord: "ədəbiyyat",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "literature",
    turkishWord: "edebiyat", // Added Turkish word
  },
  {
    mainWord: "tarix",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "history",
    turkishWord: "tarih", // Added Turkish word
  },
  {
    mainWord: "mədəniyyət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "culture",
    turkishWord: "kültür", // Added Turkish word
  },
  {
    mainWord: "incəsənət",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "art",
    turkishWord: "sanat", // Added Turkish word
  },
  {
    mainWord: "musiqi",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "music",
    turkishWord: "müzik", // Added Turkish word
  },
  {
    mainWord: "rəqs",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "dance",
    turkishWord: "dans", // Added Turkish word
  },
  {
    mainWord: "teatr",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "theater",
    turkishWord: "tiyatro", // Added Turkish word
  },
  {
    mainWord: "kino",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "cinema",
    turkishWord: "sinema", // Added Turkish word
  },
  {
    mainWord: "film",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "movie",
    turkishWord: "film", // Added Turkish word
  },
  {
    mainWord: "şeir",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "poem",
    turkishWord: "şiir", // Added Turkish word
  },
  {
    mainWord: "roman",
    relatedWords: ["rəng", "çiçək", "gözəllik", "sakitlik", "təbiət"],
    englishWord: "novel",
    turkishWord: "roman", // Added Turkish word
  },
];

// ... existing code ...
export const getRelatedWords = (mainWord, lang, count = 3) => {
  const wordObj =
    lang === "az"
      ? wordsList.find((w) => w.mainWord === mainWord)
      : lang === "tr"
      ? wordsList.find((w) => w.turkishWord === mainWord)
      : wordsList.find((w) => w.englishWord === mainWord);
  if (!wordObj) return [];

  // Shuffle and get random related words
  return [...wordObj.relatedWords]
    .sort(() => Math.random() - 0.5)
    .slice(0, count);
};

// For backwards compatibility, export arrays of just the main words
export const words = wordsList.map((w) => w.mainWord);
export const englishWords = wordsList.map((w) => w.englishWord);
export const turkishWords = wordsList.map((w) => w.turkishWord); // Added Turkish words export


export  const translations = {
  en: {
    title: 'Spy Game',
    subtitle: 'No one should know that you are a spy!',
    whatOurAim: 'What Our Aim',
    playMusic: 'Play Music',
    pauseMusic: 'Pause Music',
    usedWords: 'Used words',
    remainingWords: 'Remaining words',
    resetGame: 'Reset Game',
    modalTitle: 'What Our Aim Is',
    modalContent: 'The "Spy" game was created with a simple yet powerful goal: to bring people together through an engaging and fun experience...',
    close: 'Close',
    gameMode: 'Game Mode',
    classicMode: 'Classic Mode',
    relatedWordsMode: 'Related Words Mode',
    classicModeDescription: 'Classic spy game with single word per player',
    relatedWordsDescription: 'Spy game with related words shown under spy word'
  },
  az: {
    title: 'Casus Oyunu',
    subtitle: 'Heç kim bilməməlidir ki, sən casussan!',
    whatOurAim: 'Məqsədimiz Nədir',
    playMusic: 'Musiqini Başlat',
    pauseMusic: 'Musiqini Dayandır',
    usedWords: 'İstifadə edilmiş sözlər',
    remainingWords: 'Qalan sözlər',
    resetGame: 'Oyunu Sıfırla',
    modalTitle: 'Məqsədimiz Nədir',
    modalContent: '"Casus" oyunu insanları maraqlı və əyləncəli bir təcrübə ilə bir araya gətirmək üçün sadə, lakin güclü bir məqsədlə yaradılıb...',
    close: 'Bağla',
    gameMode: 'Oyun Rejimi',
    classicMode: 'Klassik Rejim',
    relatedWordsMode: 'Əlaqəli Sözlər Rejimi',
    classicModeDescription: 'Hər oyunçu üçün tək söz olan klassik casus oyunu',
    relatedWordsDescription: 'Casus sözün altında əlaqəli sözlərin göstərildiyi oyun'
  },
  tr: {
    title: 'Casus Oyunu',
    subtitle: 'Kimse casus olduğunuzu bilmemeli!',
    whatOurAim: 'Amacımız Nedir',
    playMusic: 'Müziği Başlat',
    pauseMusic: 'Müziği Durdur',
    usedWords: 'Kullanılan kelimeler',
    remainingWords: 'Kalan kelimeler',
    resetGame: 'Oyunu Sıfırla',
    modalTitle: 'Amacımız Nedir',
    modalContent: '"Casus" oyunu, insanları eğlenceli ve ilgi çekici bir deneyimle bir araya getirmek için basit ama güçlü bir amaçla oluşturulmuştur...',
    close: 'Kapat',
    gameMode: 'Oyun Modu',
    classicMode: 'Klasik Mod',
    relatedWordsMode: 'İlgili Kelimeler Modu',
    classicModeDescription: 'Her oyuncu için tek kelime ile klasik casus oyunu',
    relatedWordsDescription: 'Casus kelimenin altında ilgili kelimelerin gösterildiği oyun'
  }
};


export const formTranslations = {
  en: {
    title: 'Spy Game Setup',
    subtitle: 'Prepare the game by entering participant and spy counts!',
    participants: 'Total Participants',
    spies: 'Spy Count',
    startGame: 'Start Game',
    participantsPlaceholder: 'Enter the number of players...',
    spiesPlaceholder: 'Enter the number of spies...'
  },
  az: {
    title: 'Casus Oyunu Quraşdırması',
    subtitle: 'İştirakçı və casus saylarını daxil edərək oyunu hazırlayın!',
    participants: 'Ümumi İştirakçılar',
    spies: 'Casus Sayı',
    startGame: 'Oyunu Başlat',
    participantsPlaceholder: 'Oyunçu sayını daxil edin...',
    spiesPlaceholder: 'Casus sayını daxil edin...'
  },
  tr: {
    title: 'Casus Oyunu Kurulumu',
    subtitle: 'Katılımcı ve casus sayılarını girerek oyunu hazırlayın!',
    participants: 'Toplam Katılımcı',
    spies: 'Casus Sayısı',
    startGame: 'Oyunu Başlat',
    participantsPlaceholder: 'Oyuncu sayısını girin...',
    spiesPlaceholder: 'Casus sayısını girin...'
  }
};